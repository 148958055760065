// const inriaSans = Inria_Sans({
//   weight: ["700", "400"],
//   style: ["normal", "italic"],
//   subsets: ["latin"],
// })

// const arialMTStdFont = localFont({
//   src: [
//     {
//       path: "../assets/fonts/ArialMTStd/ArialMTStd-ExtraBold.otf",
//       weight: "900",
//       style: "normal",
//     },
//   ],
// })

@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@font-face {
  font-family: "Univers LT";
  src: url("../fonts/UniversLT/UniversLT-Light.ttf");
  font-weight: 300;
  font-style: "normal";
}

@font-face {
  font-family: "Univers LT";
  src: url("../fonts/UniversLT/UniversLT-Regular.ttf");
  font-weight: 400;
  font-style: "normal";
}

@font-face {
  font-family: "Univers LT";
  src: url("../fonts/UniversLT/UniversLT-Bold.ttf");
  font-weight: 700;
  font-style: "normal";
}

@font-face {
  font-family: "Univers LT";
  src: url("../fonts/UniversLT/UniversLT-ExtraBold.ttf");
  font-weight: 900;
  font-style: "normal";
}

@font-face {
  font-family: "Arial MT Std";
  src: url("../fonts/ArialMTStd/ArialMTStd-ExtraBold.otf");
  font-weight: 900;
  font-style: "normal";
}
