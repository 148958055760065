@import "./fonts.scss";
@import "react-toastify/dist/ReactToastify.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "aos/dist/aos.css";

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes zoomOut {
  from {
    background-size: 40%;
  }
  to {
    background-size: 100%;
  }
}
@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0px, 50px);
  }
}
@keyframes shimmy-mirror {
  0% {
    transform: translate(0px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
